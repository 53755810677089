import config from './config.json';
import utilities from './utilities.js';

class Theme {

    constructor() {
        
        this.config = config;

        this.init();
    }


    // Class initialisation
    init() {
        const thisClass = this;
        const thisConfig = thisClass.config;

        if (thisConfig.accordion.activated) thisClass.accordion();
        if (thisConfig.collapse.activated) thisClass.collapse();
        if (thisConfig.confirmStep.activated) thisClass.confirmStep();
        if (thisConfig.gallery.activated) thisClass.gallery();
        if (thisConfig.headerMobile.activated) thisClass.headerMobile();
        if (thisConfig.headerSticky.activated) thisClass.headerSticky();
        if (thisConfig.niceSelect2.activated) thisClass.niceSelect2();
        if (thisConfig.preloading.activated) thisClass.preloading();
        if (thisConfig.scrollTop.activated) thisClass.scrollTop();
        if (thisConfig.seePassword.activated) thisClass.seePassword();
        if (thisConfig.templateDuplicate.activated) thisClass.templateDuplicate();
    }


    // Accordion
    accordion() {
        const accordionItems = document.querySelectorAll('.accordion__header');
        accordionItems.forEach(element => {
            element.addEventListener('click', function() {
                this.closest('.accordion__item').classList.toggle('active');
            });
        });
    }


    // Collapse 
    collapse() {

        // Set the active tab link element and show the appropriate tab
        const setActiveTab = (tab) => {
            const parentTab = tab.parentNode;
            const childrenTab = parentTab.children;
            const indexTab = Array.prototype.indexOf.call(childrenTab, tab);
            const parentContent = parentTab.nextElementSibling;
            const childrenContent = parentContent.children;

            // Remove the active class for all siblings tab links of the current tab link
            for (const childTab of childrenTab) {
                if (childTab.classList.contains('active') && childTab != tab) {
                    childTab.classList.remove('active');
                }
            }

            // Show the appropriate tab
            for (let i=0 ; i<childrenContent.length ; i++) {
                if (i == indexTab) {
                    childrenContent[i].classList.add('active');
                } else {
                    childrenContent[i].classList.remove('active');
                }
            }

        }

        document.querySelectorAll('.collapse__item').forEach(element => {

            if (element.classList.contains('active')) {
                setActiveTab(element);
            }

            element.addEventListener('click', function() {
                this.classList.add('active');
                setActiveTab(this);
            });
        });
    }


    // Confirm step
    confirmStep() {
        const conditionAcceptBtn = document.querySelectorAll('[data-condition-accept]');
        const conditionReturnBtn = document.querySelectorAll('[data-condition-back]');

        // For each condition accept buttons
        conditionAcceptBtn.forEach(button => {
            // When the user is accepting a condition
            button.addEventListener('click', function() {
                const parent = this.closest('.condition-step__item');
                const next = parent.nextElementSibling;

                // Go to the next step
                parent.classList.add('accepted');
                parent.classList.remove('active');
                next.classList.add('active');

            });
        });

        // For each condition back buttons
        conditionReturnBtn.forEach(button => {
            // When the user want to return to a condition
            button.addEventListener('click', function() {
                const parent = this.closest('.condition-step__item');
                const parentRoot = parent.closest('.condition-step');
                const returnId = this.getAttribute('data-condition-back');
                const returnElt = parentRoot.querySelector(returnId);

                // Go back to the step
                parent.classList.remove('active');
                returnElt.classList.add('active');

            });
        });
    }


    // Gallery
    gallery() {
        const galleries = document.querySelectorAll('.gallery');

        if (galleries.length != 0) {
            galleries.forEach(gallery => {
                lightGallery(gallery);
            });
        }
    }


    // Header mobile
    headerMobile() {
        const header = document.querySelector('.header');
        const headerNav = document.querySelector('.header__nav');
        const headerOpen = document.querySelector('.header__mobile-open');
        const headerClose = document.querySelector('.header__mobile-close');

        // The user want to close the header mobile navigation (click outside of the header menu)
        headerNav.addEventListener('click', function(e) {
            // Check if the user is clicking outside of the header menu
            if (e.target == headerNav) {
                header.classList.remove('is-open');
            }
        });

        // The user want to close the header mobile navigation (click in the close button)
        headerClose.addEventListener('click', function(e) {
            header.classList.remove('is-open');
        });

        // The user want to open the header mobile navigation 
        headerOpen.addEventListener('click', function() {
            header.classList.add('is-open');
        });
    }


    // Header sticky
    headerSticky() {
        const thisClass = this;
        const globalConfig = thisClass.config._global;
        const headerStickyConfig = thisClass.config.headerSticky;
        // >>>
        const header = document.querySelector('.header');
        const bodyWrapper = globalConfig.bodyWrapper != "" ? document.querySelector(globalConfig.bodyWrapper) : window;

        // Check if the header has to be sticky or not 
        const checkHeaderSticky = () => {
            if (bodyWrapper.scrollTop >= headerStickyConfig.stickyAfterScrolling) {
                header.classList.add('is-sticky');
            } else {
                header.classList.remove('is-sticky');
            }
        }

        // When the page is loaded
        checkHeaderSticky();
        
        // When the user is scrolling the page
        bodyWrapper.addEventListener('scroll', () => {
            checkHeaderSticky();
        });
    }


    // Nice select 2
    niceSelect2() {
        const niceSelect2 = document.querySelectorAll('.select-nice');
        if (niceSelect2.length != 0) {
            niceSelect2.forEach(select => {
                NiceSelect.bind(select, {
                    placeholder: "Choisissez une option",
                });
            });
        }
    }


    // Adding the preloading functionnality to the body
    preloading() {
        window.addEventListener('DOMContentLoaded', function() {
            document.body.classList.remove('preload');
        });
    }


    // Scroll top
    scrollTop() {
        const thisClass = this;
        const scrollConfig = thisClass.config.scrollTop;
        const globalConfig = thisClass.config._global;
        // >>>
        const scrollTopElt = document.querySelector('.scroll-top');
        const bodyWrapper = globalConfig.bodyWrapper != "" ? document.querySelector(globalConfig.bodyWrapper) : window;

        // Function to show or hide the scroll top element
        const showHideScrollTop = (distance) => {
            if (distance >= scrollConfig.showAfterScrolling) {
                scrollTopElt.classList.add('active');
            } else {
                scrollTopElt.classList.remove('active');
            }
        }

        // Function to scroll to the top of the page smoothly
        const scrollToTop = () => {
            const c = bodyWrapper.scrollTop;
            scrollConfig.requestAnimation = window.requestAnimationFrame(scrollToTop);
            bodyWrapper.scrollTo(0, c - c / scrollConfig.scrollSmooth);
        }

        // When the page is loaded
        showHideScrollTop(bodyWrapper.scrollTop);

        // When the user is scrolling the page
        bodyWrapper.addEventListener('scroll', () => {
            showHideScrollTop(bodyWrapper.scrollTop);

            if (bodyWrapper.scrollTop === 0 && scrollConfig.requestAnimation != "") {
                cancelAnimationFrame(scrollConfig.requestAnimation);
                scrollConfig.requestAnimation = "";
            }
        });

        // When the user is clicking on the scroll top element
        scrollTopElt.addEventListener('click', () => {
            scrollToTop();
        });

    }

    // Change input password into text
    seePassword() {
        document.querySelectorAll('.see-password').forEach(element => {
            const input = element.closest('.see-password-wrap').querySelector('input');
            // When the user click on the icon
            element.addEventListener('click', function() {
                // Add active class to the icon
                this.classList.toggle('active');
                // Change the type of the input
                if (input.getAttribute('type') == 'password') {
                    input.setAttribute('type', 'text');
                } else {
                    input.setAttribute('type', 'password');
                }
            });            
        });
    }

    // Duplicate template on click button
    templateDuplicate() {
        const triggers = document.querySelectorAll('[data-template-duplicate]');
        let counter = 0;

        // For each templates
        triggers.forEach(trigger => {
            const templateId = trigger.getAttribute('data-template-duplicate');
            const templateType = trigger.getAttribute('data-template-type');
            const template = document.querySelector(templateId);

            // When the user wants to duplicate the template
            trigger.addEventListener('click', function() {
                const templateParent = template.parentNode;
                const templateClone = template.content.cloneNode(true);
                const templateWrapper = template.parentNode;

                // Add a "parent mark" on the first children of the template 
                const firstTemplateChildren = templateClone.firstElementChild;
                firstTemplateChildren.setAttribute('data-template-wrap', "");


                // If the template type is a form, then add a functionnality with different ids for all inputs inside
                if (templateType != undefined && templateType == "form") {
                    const inputs = templateClone.querySelectorAll('input, textarea, select');

                    // Increment the counter and add the value of the counter at the end of the id and name of the input
                    counter++;
                    inputs.forEach(input => {
                        const inputId = input.getAttribute('id');
                        let inputName = input.getAttribute('name');
                        const label = templateClone.querySelector(`[for="${inputId}"]`);

                        // Check if there is a number to add inside the name of the input (if the name contains "[.]")
                        
                        if(inputName.indexOf('[.]') != -1) {
                            inputName = inputName.replace('[.]', `[${counter}]`);
                        }

                        label.setAttribute('for', inputId + counter);
                        input.setAttribute('id', inputId + counter);
                        input.setAttribute('name', inputName);
                    });
                }

                // Append the template clone to the closest parent of this last
                templateParent.append(templateClone);
            });

            // When the user wants to delete a cloned template
            onDocumentEvent('click', `[data-template-remove]`, function(self, e) {
                const parent = self.closest('[data-template-wrap]');
                parent.remove();
            });
            
        });
    }

}

document.addEventListener('DOMContentLoaded', () => {
    new Theme();
});
